import React from "react";
import PropTypes from "prop-types";
import { FaMapMarkerAlt } from 'react-icons/fa';
import './Badge.style.css';

const propTypes = {
  /** BadgeSize you can pass if you want big badge (ex: big, medium) */
  badgeSize: PropTypes.string,
  /** Badge Text is Required in order to show a badge */
  badgeText: PropTypes.string.isRequired,
  /** className for custom manipulation */
  className: PropTypes.string,
  /** font Style is optional field for font (bold, italic, semibold, normal) */
  fontStyle: PropTypes.string,
  /** Icon Color For clear badges */
  iconColor: PropTypes.string,
  /** isLoading is for loading element in badges */
  isLoading: PropTypes.bool,
  /** Text Style is optional field for text (uppercase, lowercase , capitalize) */
  textStyle: PropTypes.string,
  /** type of the field (teal, clear, pin ) */
  type: PropTypes.string.isRequired,
};

const defaultProps = {
  disabled: false,
  className: "",
  fontStyle: "bold",
  textStyle: "capitalize",
  iconColor: "#009BA7",
  isLoading: false,
  badgeSize: "",
};

function Badge(props) {
  const { className, type, badgeText, fontStyle, textStyle, isLoading, badgeSize } = props;

  return (
    <React.Fragment>
      {type === "teal" && (
        <div
          className={`${
            badgeSize === "big" ? "big-tag" : "tag"
            } tag-teal ${textStyle} ${fontStyle} ${className} ${isLoading ? "content-loading rounded" : ""}`}
        >
          {badgeText}
        </div>
      )}
      
      {type === "clear" && (
        <div
          className={`${
            badgeSize === "big" ? "big-tag" : "tag"
            } tag-clear  ${textStyle} ${fontStyle} ${className} ${isLoading ? "content-loading rounded" : ""}`}
        >
          {badgeText}
        </div>
      )}
      {type === "pin" && (
        <div className={`${badgeSize === "big" ? "big-tag" : "tag"} tag-pin  ${textStyle} ${fontStyle} ${className} ${isLoading ? "content-loading rounded" : ""
          }`}
        >
          <FaMapMarkerAlt className='mr-2 text-teal' />{badgeText}
        </div>
      )}
    </React.Fragment>
  );
}

Badge.propTypes = propTypes;
Badge.defaultProps = defaultProps;

export default Badge;
