import axios from "axios";
import cookie from "../actions/cookie.js";
import { API_END_POINT } from "../config/env.json";

export const podcast = (limnit, page) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.get(`${API_END_POINT}/v1/podcast-lists/${limnit}?page=${page}`, options)
        .then(res => res)
        .catch(error => error);
};

export const video = (limnit, page) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.get(`${API_END_POINT}/v1/vimeo-lists/${limnit}?page=${page}`, options)
        .then(res => res)
        .catch(error => error);
};

export const addFav = (payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.post(`${API_END_POINT}/v1/fav-media`, payload, options)
        .then(res => res)
        .catch(error => error);
};

export const removeFav = (id) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.post(`${API_END_POINT}/v1/remove-fav-media/${id}`, null, options)
        .then(res => res)
        .catch(error => error);
};

export const addHistory = (payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.post(`${API_END_POINT}/v1/add-media-history`, payload, options)
        .then(res => res)
        .catch(error => error);
};

export const getHistory = () => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.get(`${API_END_POINT}/v1/list-media-history`, options)
        .then(res => res)
        .catch(error => error);
};