/***
 *
 * Get the token from cookie and parse it to get valid json
 * CheckCookie function also check for cookies existense
 * Which holds user Informations.
 *
 * Author; Arijit Deb Roy
 * Date: 2nd sep,2019
 *
 * @param: void
 * @return: <JSON>
 *
 */
import cookie from './cookie';

const auth = {
    check() {
        if(cookie.getCookie('_token') && cookie.getCookie('_session')){
            var base64Url = cookie.getCookie('_session');
            var jsonPayload = atob(base64Url);
            return JSON.parse(jsonPayload);
        }else{
            return false;
        }
    },

    hasCookie() {
        if(cookie.getCookie('_token') && cookie.getCookie('_session'))
            return true;
        else
            return false;
    },

    update(key, value) {
        let data = this.check();
        data[key] = value;
        cookie.setCookie("_session", btoa(JSON.stringify(data)), 1);
    }
}

export default auth;