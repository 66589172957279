/* eslint-disable */
import React, {Component} from 'react';
import guard from "../actions/guard";
import ParentStudent from "../components/Profile/ParentStudent";
import Tutor from "../components/Profile/Tutor";

class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            role: null
        }
    }

    componentDidMount() {
        guard.check(() => {
            this.setState({
                role: guard.role()
            })
        });
    }

    render() {
        return (
          <>
            {
                this.state.role === "tutor" ?
                <Tutor />
                :
                <ParentStudent />
            }
          </>
        );
    }
}

export default Profile;