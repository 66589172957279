/* eslint-disable */
import React, { useReducer, useState, useEffect } from "react";
import StreamPlayer from "agora-stream-player";
import { useCamera, useMicrophone, useMediaStream } from "../hooks";
import AgoraRTC from "../services/Enhancer";
import { FaMicrophone, FaMicrophoneSlash, FaVideoSlash, FaVideo, FaPhoneSlash } from "react-icons/fa";

const defaultState = {
    appId: "0ea437356c36435aaa7314efb830c89c",
    uid: "",
    token: null,
    cameraId: "",
    microphoneId: "",
    mode: "rtc",
    codec: "h264"
};

const reducer = (state, action) => {
    switch (action.type) {
        default:
            return state;
        case "setAppId":
            return {
                ...state,
                appId: action.value
            };
        case "setUid":
            return {
                ...state,
                uid: action.value
            };
        case "setToken":
            return {
                ...state,
                token: action.value
            };
        case "setCamera":
            return {
                ...state,
                cameraId: action.value
            };
        case "setMicrophone":
            return {
                ...state,
                microphoneId: action.value
            };
        case "setMode":
            return {
                ...state,
                mode: action.value
            };
        case "setCodec":
            return {
                ...state,
                codec: action.value
            };
    }
};

function Agora({roomToken, end}) {
    const [isJoined, setisJoined] = useState(false);
    const [isPublished, setIsPublished] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isAudioMuted, setIsAudioMuted] = useState(false);
    const [isVideoMuted, setIsVideoMuted] = useState(false);
    const [state, dispatch] = useReducer(reducer, defaultState);
    const [agoraClient, setClient] = useState(undefined);

    const cameraList = useCamera();
    const microphoneList = useMicrophone();
    let [localStream, remoteStream, streamList] = useMediaStream(agoraClient);

    useEffect(() => {
        cameraList.length > 0 && inputDeviceChange('setCamera', cameraList[0].deviceId);
        microphoneList.length > 0 && inputDeviceChange('setMicrophone', microphoneList[0].deviceId);
    }, [cameraList, microphoneList]);

    useEffect(() => {
        state.cameraId !== "" && localStream &&
        localStream.switchDevice('video', state.cameraId, () => {
            alert("camera change success");
        }, (err) => {
            alert("camera change fail");
        });
    }, [state.cameraId]);

    useEffect(() => {
        state.microphoneId !== "" && localStream &&
        localStream.switchDevice('audio', state.microphoneId,  () => {
            alert("mic change success");
        }, (err) => {
            alert("mic change fail");
        });
    }, [state.microphoneId]);

    useEffect(() => {
        if(roomToken) {
            join(roomToken);
        }
    }, [roomToken])

    const update = (actionType) => (e) => {
        return dispatch({
            type: actionType,
            value: e.target.value
        });
    };

    const inputDeviceChange = (actionType, deviceId) => {
        return dispatch({
            type: actionType,
            value: deviceId
        });
    };

    const muteAudio = async () => {
        let res = await localStream.muteAudio();
        if(res) {
            setIsAudioMuted(true);
        }
    };

    const unMuteAudio = async () => {
        let res = await localStream.unmuteAudio();
        if(res) {
            setIsAudioMuted(false);
        }
    };

    const muteVideo = async () => {
        let res = await localStream.muteVideo();
        if(res) {
            setIsVideoMuted(true);
        }
    };

    const unMuteVideo = async () => {
        let res = await localStream.unmuteVideo();
        if(res) {
            setIsVideoMuted(false);
        }
    };

    // Starts the video call
    const join = async (roomToken) => {
        // Creates a new agora client with given parameters.
        // mode can be 'rtc' for real time communications or 'live' for live broadcasting.
        const client = AgoraRTC.createClient({ mode: state.mode, codec: state.codec })
        // Loads client into the state
        setClient(client)
        setIsLoading(true);
        try {
            const uid = isNaN(Number(state.uid)) ? null : Number(state.uid);

            // initializes the client with appId
            await client.init(state.appId);

            // joins a channel with a token, channel, user id
            await client.join(state.token, roomToken, uid);

            // create a new stream
            const stream = AgoraRTC.createStream({
                streamID: uid || 12345,
                video: true,
                audio: true,
                screen: false
            });

            stream.setVideoProfile('480p_9')

            // Initalize the stream
            await stream.init();

            // Publish the stream to the channel.
            await client.publish(stream);

            // Set the state appropriately
            setIsPublished(true);
            setisJoined(true);
        } catch (err) {
            alert(`Failed to join, ${err}`);
        } finally {
            setIsLoading(false);
        }
    };

    // Publish function to publish the stream to Agora. No need to invoke this after join.
    // This is to be invoke only after an unpublish
    const publish = async () => {
        setIsLoading(true);
        try {
            if (localStream) {
                // Publish the stream to the channel.
                await agoraClient.publish(localStream);
                setIsPublished(true);
            }
            alert("Stream published");
        } catch (err) {
            alert(`Failed to publish, ${err}`);
        } finally {
            setIsLoading(false);
        }
    };

    // Leaves the channel on invoking the function call.
    const leave = async () => {
        setIsLoading(true);
        try {
            if (localStream) {
                // Closes the local stream. This de-allocates the resources and turns off the camera light
                localStream.close();
                // unpublish the stream from the client
                agoraClient.unpublish(localStream);
            }
            // leave the channel
            await agoraClient.leave();
            setIsPublished(false);
            setisJoined(false);
            end();
        } catch (err) {
            alert(`Failed to leave, ${err}`);
        } finally {
            setIsLoading(false);
        }
    };

    // Used to unpublish the stream.
    const unpublish = () => {
        if (localStream) {
            // unpublish the stream from the client
            agoraClient.unpublish(localStream);
            setIsPublished(false);
            alert("Stream unpublished");
        }
    };

    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-center room-btn-container">
                {
                    isVideoMuted ? 
                    <button className="btn btn-link mx-3" onClick={unMuteVideo} disabled={isLoading || isAudioMuted}><FaVideoSlash size="24"/></button>
                    :
                    <button className="btn btn-link mx-3" onClick={muteVideo} disabled={isLoading || isAudioMuted}><FaVideo size="24"/></button>
                }
                {
                    isJoined &&
                    <button className="btn btn-link mx-3" onClick={leave} disabled={isLoading}><FaPhoneSlash size="24" color="red"/></button>
                }
                {
                    isAudioMuted ?
                    <button className="btn btn-link mx-3" onClick={unMuteAudio} disabled={isLoading || isVideoMuted}><FaMicrophoneSlash size="24"/></button>
                    :
                    <button className="btn btn-link mx-3" onClick={muteAudio} disabled={isLoading || isVideoMuted}><FaMicrophone size="24"/></button>
                }
            </div>
            <div className="d-flex justify-content-center mb-3">
                {
                    localStream && 
                        <StreamPlayer 
                            stream={localStream} 
                            fit="contain" 
                        />
                }
            </div>
            <div className="d-flex justify-content-center">
                {
                    remoteStream && 
                        <StreamPlayer
                            key={remoteStream.getId()}
                            stream={remoteStream}
                            fit="contain"
                        />
                }
            </div>
        </div>
    );
}

export default Agora;