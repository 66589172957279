/* eslint-disable */
import React, {Component} from 'react';
import guard from "../actions/guard";
import auth from "../actions/auth";
import AuthLayout from "../components/AuthLayout";
import TutorLayout from "../components/TutorLayout";
import Agora from "../components/Agora";
import { FaPencilAlt, FaMousePointer, FaEraser, FaTextWidth } from "react-icons/fa";
import { BsBoundingBoxCircles, BsCircle } from "react-icons/bs";
import { MdColorLens } from "react-icons/md";
import { RoomPhase, WhiteWebSdk, RoomWhiteboard } from "white-react-sdk";
import { startSession, endSession } from "../services/Common";
import {get} from "../services/Profile";
import {toast} from "react-toastify";
import moment from "moment";

class ClassRoom extends Component {

    static sdkToken = "NETLESSSDK_YWs9MXU5QzgwSHBTQjlYRTVqUiZub25jZT0xNjA1NzA5NjI3MzgxMDAmcm9sZT0wJnNpZz1jNGYxNDI5NzYzZWM1Mzc0MGY3ZjZhYTNhNzk1MzhiNGM3YWJiMjMwMzRjZTVhODJjMDY1YTlkZDZlOTAwZDk0";
    static whiteWebSdk = new WhiteWebSdk({
        appIdentifier: "mnBBECmmEeu0Xy2cBkFEzw/GNnp5O0W5QVSzw",
    });

    constructor(props) {
        super(props);
        this.state = {
            room: null,
            appliance : "" ,
            phase : RoomPhase.Connecting,
            color: "0, 0, 0",
            counter: null,
            sessionId: null,
            schedule: {},
            remainingTime: 0
        }
    }

    componentDidMount() {
        window.addEventListener("beforeunload", this.handler);
        guard.check(() => {
            if(guard.role() === "tutor") {
                get(auth.check().id).then((res) => {
                    if(res.status === 200) {
                        const schedule = _.find(res.data.data.schedules, {is_completed: 0, approved: 1, id: parseInt(atob(this.props.match.params.id))});
                        if(schedule.schedule_session !== null) {
                            if(moment(moment(schedule.schedule_date).format("YYYY-MM-DD")).isSame(moment().format("YYYY-MM-DD"))
                                && moment(moment(`${schedule.schedule_date} ${schedule.end_time}`).format("YYYY-MM-DD HH:mm:ss")).isSameOrAfter(moment().format("YYYY-MM-DD HH:mm:ss"))
                                && moment.duration(moment(`${schedule.schedule_date} ${schedule.end_time}`, ["YYYY-MM-DD HH.mm"]).diff(moment(moment().format("YYYY-MM-DD HH:mm:ss"), ["YYYY-MM-DD HH.mm"]))).asSeconds() > 0
                            ) {
                                this.setState({
                                    ...this.state,
                                    sessionId: schedule.schedule_session.id,
                                    schedule: schedule,
                                    remainingTime: moment.duration(moment(`${schedule.schedule_date} ${schedule.end_time}`, ["YYYY-MM-DD HH.mm"]).diff(moment(moment().format("YYYY-MM-DD HH:mm:ss"), ["YYYY-MM-DD HH.mm"]))).asSeconds()
                                }, () => {
                                    this.join();
                                });
                            }else {
                                toast.error("This room has been closed/not started.");
                                setTimeout(() => {
                                    // this.props.history.push("/dashboard");
                                }, 1000);
                            }
                        }else {
                            let input = {
                                "schedule_id": atob(this.props.match.params.id),
                                "start_time": moment().format('YYYY-MM-DD HH:mm:ss')
                            }
                            startSession(input).then((res) => {
                                if(res.status === 201) {
                                    if(moment(moment(res.data.schedule.schedule_date).format("YYYY-MM-DD")).isSame(moment().format("YYYY-MM-DD"))
                                        && moment(moment(`${res.data.schedule.schedule_date} ${res.data.schedule.end_time}`).format("YYYY-MM-DD HH:mm:ss")).isSameOrAfter(moment().format("YYYY-MM-DD HH:mm:ss"))
                                        && moment.duration(moment(`${res.data.schedule.schedule_date} ${res.data.schedule.end_time}`, ["YYYY-MM-DD HH.mm"]).diff(moment(moment().format("YYYY-MM-DD HH:mm:ss"), ["YYYY-MM-DD HH.mm"]))).asSeconds() > 0
                                    ) {
                                        this.setState({
                                            ...this.state,
                                            sessionId: res.data.id,
                                            schedule: res.data.schedule,
                                            remainingTime: moment.duration(moment(`${res.data.schedule.schedule_date} ${res.data.schedule.end_time}`, ["YYYY-MM-DD HH.mm"]).diff(moment(moment().format("YYYY-MM-DD HH:mm:ss"), ["YYYY-MM-DD HH.mm"]))).asSeconds()
                                        }, () => {
                                            this.join();
                                        });
                                    }else {
                                        toast.error("This room has been closed/not started.");
                                        setTimeout(() => {
                                            // this.props.history.push("/dashboard");
                                        }, 1000);
                                    }
                                }else {
                                    toast.error("Something went wrong!");
                                }
                            }).catch((err) => {
                                toast.error("Something went wrong!");
                            });
                        }
                    }else {
                        toast.error("Something went wrong!");
                    }
                }).catch((err) => {
                    toast.error("Something went wrong!");
                })
            }else {
                get(auth.check().id).then((res) => {
                    if(res.status === 200) {
                        const schedule = _.find(res.data.data.session_scheduled, {is_completed: 0, approved: 1, id: parseInt(atob(this.props.match.params.id))});
                        if(moment(moment(schedule.schedule_date).format("YYYY-MM-DD")).isSame(moment().format("YYYY-MM-DD"))
                            && moment(moment(`${schedule.schedule_date} ${schedule.end_time}`).format("YYYY-MM-DD HH:mm:ss")).isSameOrAfter(moment().format("YYYY-MM-DD HH:mm:ss"))
                            && moment.duration(moment(`${schedule.schedule_date} ${schedule.end_time}`, ["YYYY-MM-DD HH.mm"]).diff(moment(moment().format("YYYY-MM-DD HH:mm:ss"), ["YYYY-MM-DD HH.mm"]))).asSeconds() > 0
                        ) {
                            this.setState({
                                ...this.state,
                                schedule: schedule,
                                remainingTime: moment.duration(moment(`${schedule.schedule_date} ${schedule.end_time}`, ["YYYY-MM-DD HH.mm"]).diff(moment(moment().format("YYYY-MM-DD HH:mm:ss"), ["YYYY-MM-DD HH.mm"]))).asSeconds()
                            }, () => {
                                this.join();
                            });
                        }else {
                            toast.error("This room has been closed/not started.");
                            setTimeout(() => {
                                // this.props.history.push("/dashboard");
                            }, 1000);
                        }
                    }else {
                        toast.error("Something went wrong!");
                    }
                }).catch((err) => {
                    toast.error("Something went wrong!");
                })
            }
        });
    }

    handler = (event) => {
        event.preventDefault();
        event.returnValue = "Changes are not saved!";
    }

    join = () => {
        this.createRoomToken(this.state.schedule.netless_uuid).then((roomToken) => {
            return ClassRoom.whiteWebSdk.joinRoom({
                uuid: this.state.schedule.netless_uuid,
                roomToken: roomToken,
            }, {
                onPhaseChanged : phase  =>  this.setState ({ phase }),
                onRoomStateChanged : ({ memberState })  =>  {
                    if( memberState  &&  memberState.currentApplianceName !== this.state.appliance )  {
                        this.setState ({ 
                            ...this.state,
                            appliance : memberState.currentApplianceName 
                        });
                    }
                },
            });
        }).then((room) => {
            let counter = setInterval(() => {
                if(this.state.remainingTime > 0) {
                    this.state.remainingTime -= 1;
                }else {
                    clearInterval(this.state.counter);
                    // this.end();
                }
            }, 1000);
            this.setState({
                ...this.state,
                room: room,
                appliance : room.state.memberState.currentApplianceName,
                counter: counter
            });
        }).catch((err) => {
            toast.error(err.message);
        });
    }

    currentRoomPhaseDescription = ( ) => {
        switch  ( this.state.phase )  {
            case  RoomPhase.Connecting : {
                return  "Connecting" ;
            }
            case  RoomPhase.Connected : {
                return  "Connected" ;
            }
            case  RoomPhase.Reconnecting : {
                return  "Reconnecting" ;
            }
            case  RoomPhase.Disconnecting : {
                return  "Disconnecting" ;
            }
            case  RoomPhase.Disconnected : {
                return  "Leave" ;
            }
            default : {
                return  this.state.phase ;
            }
        }
    }

    end = () => {
        if(this.state.sessionId) {
            let input = {
                "id": this.state.sessionId,
                "schedule_id": atob(this.props.match.params.id),
                "end_time":  moment().format('YYYY-MM-DD HH:mm:ss')
            }
            endSession(input).then((res) => {
                if(res.status === 200) {
                    if(this.currentRoomPhaseDescription() === "Connected") {
                        this.state.room.disconnect();
                    }
                    this.props.history.push("/dashboard");
                }else {
                    toast.error("Something went wrong!");
                }
            }).catch((err) => {
                toast.error("Something went wrong!");
            });
        }else {
            if(this.currentRoomPhaseDescription() === "Connected") {
                this.state.room.disconnect();
            }
            this.props.history.push("/dashboard");
        }
    }

    createRoomToken(roomUUID) {
        var url = "https://shunt-api.netless.link/v5/tokens/rooms/" + roomUUID;
        var requestInit = {
            method: "POST",
            headers: {
                "content-type": "application/json",
                "token": ClassRoom.sdkToken,
            },
            body: JSON.stringify({
                "lifespan": 0, // indicates that the Room Token will never expire
                "role": "admin", // indicates that Room Token has Admin authority
            }),
        };
        return window.fetch(url, requestInit).then((response) => {
            return response.json();
        });
    }

    changeAppliences = (appliance) => {
        this.state.room.setMemberState({ 
            currentApplianceName : appliance,
            strokeColor: this.getColorCode(this.state.color)
        });
    }

    changeColor = (color) => {
        this.state.room.setMemberState({ 
            currentApplianceName: this.state.room.state.memberState.currentApplianceName,
            strokeColor: this.getColorCode(color)
        });
        this.setState({
            ...this.state,
            color: color
        });
    }

    getColorCode = (color) => {
        return color.split(",").map((val) => parseInt(val));
    }

    componentWillUnmount() {
        if(this.state.room) {
            if(this.currentRoomPhaseDescription() === "Connected") {
                this.state.room.disconnect();
            }
            clearInterval(this.state.counter);
        }
        window.removeEventListener("beforeunload", this.handler);
        if(this.state.sessionId) {
            this.end();
        }
    }

    render() {
        const { room, color } = this.state;

        return (
            <>
                {
                    guard.role() === "tutor" ?
                    <TutorLayout>
                        <div className="tutor-auth-container">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="d-flex justify-content-between mb-2 px-3 py-1">
                                        <h6 className="">
                                            Room status: { this.currentRoomPhaseDescription() }
                                        </h6>
                                        <div className="d-flex justify-content-around align-items-center appliences-containor">
                                            <FaPencilAlt onClick={this.changeAppliences.bind(this, "pencil")}/>
                                            <FaMousePointer onClick={this.changeAppliences.bind(this, "selector")}/> 
                                            <FaEraser onClick={this.changeAppliences.bind(this, "eraser")}/>
                                            <FaTextWidth onClick={this.changeAppliences.bind(this, "text")}/>
                                            <BsBoundingBoxCircles onClick={this.changeAppliences.bind(this, "rectangle")}/>
                                            <BsCircle onClick={this.changeAppliences.bind(this, "ellipse")}/>
                                            <div type="button" className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <MdColorLens color={`rgb(${color})`}/>
                                            </div>
                                            <div className="dropdown-menu">
                                                <div className="dropdown-item">
                                                    <div className="d-flex justify-content-around align-items-center appliences-containor">
                                                        <span style={{backgroundColor: "black", height: "20px", width: "20%", borderRadius: "50%"}} onClick={this.changeColor.bind(this, "0, 0, 0")}></span>
                                                        <span style={{backgroundColor: "red", height: "20px", width: "20%", borderRadius: "50%"}} onClick={this.changeColor.bind(this, "247, 8, 0")}></span>
                                                        <span style={{backgroundColor: "green", height: "20px", width: "20%", borderRadius: "50%"}} onClick={this.changeColor.bind(this, "31, 168, 0")}></span>
                                                        <span style={{backgroundColor: "blue", height: "20px", width: "20%", borderRadius: "50%"}} onClick={this.changeColor.bind(this, "4, 19, 189")}></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-100 whiteboard-container">
                                    {
                                        room &&
                                        <RoomWhiteboard room={room}
                                                style={{
                                                    width: "100%",
                                                    height: "100vh",
                                                }}/>
                                    }
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <Agora roomToken={this.state.schedule.netless_uuid} end={this.end}/>
                                </div>
                            </div>
                        </div>
                    </TutorLayout>
                    :
                    <AuthLayout>
                        <div className="auth-container">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="d-flex justify-content-between mb-2 px-3 py-1">
                                        <h6 className="">
                                            Room status: { this.currentRoomPhaseDescription() }
                                        </h6>
                                        <div className="d-flex justify-content-around align-items-center appliences-containor">
                                            <FaPencilAlt onClick={this.changeAppliences.bind(this, "pencil")}/>
                                            <FaMousePointer onClick={this.changeAppliences.bind(this, "selector")}/> 
                                            <FaEraser onClick={this.changeAppliences.bind(this, "eraser")}/>
                                            <FaTextWidth onClick={this.changeAppliences.bind(this, "text")}/>
                                            <BsBoundingBoxCircles onClick={this.changeAppliences.bind(this, "rectangle")}/>
                                            <BsCircle onClick={this.changeAppliences.bind(this, "ellipse")}/>
                                            <div type="button" className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <MdColorLens color={`rgb(${color})`}/>
                                            </div>
                                            <div className="dropdown-menu">
                                                <div className="dropdown-item">
                                                    <div className="d-flex justify-content-around align-items-center appliences-containor">
                                                        <span style={{backgroundColor: "black", height: "20px", width: "20%", borderRadius: "50%"}} onClick={this.changeColor.bind(this, "0, 0, 0")}></span>
                                                        <span style={{backgroundColor: "red", height: "20px", width: "20%", borderRadius: "50%"}} onClick={this.changeColor.bind(this, "247, 8, 0")}></span>
                                                        <span style={{backgroundColor: "green", height: "20px", width: "20%", borderRadius: "50%"}} onClick={this.changeColor.bind(this, "31, 168, 0")}></span>
                                                        <span style={{backgroundColor: "blue", height: "20px", width: "20%", borderRadius: "50%"}} onClick={this.changeColor.bind(this, "4, 19, 189")}></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-100 whiteboard-container">
                                    {
                                        room &&
                                        <RoomWhiteboard room={room}
                                                style={{
                                                    width: "100%",
                                                    height: "100vh",
                                                }}/>
                                    }
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <Agora roomToken={this.state.schedule.netless_uuid} end={this.end}/>
                                </div>
                            </div>
                        </div>
                    </AuthLayout>
                }
            </>
            
        );
    }
}

export default ClassRoom;