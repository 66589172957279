import React from 'react'
import PropTypes from 'prop-types'
import './RequestAlert.style.css'
import { MdClose, MdDone,  } from 'react-icons/md'

function RequestAlert({ show, closeFunc }) {
  return (
    <div className={`request-alert__wrapper ${show ? 'show': ''}`}>
      <button type="button" className="request-alert__close" onClick={closeFunc} data-dismiss="modal" aria-label="Close">
        <MdClose fontSize="24px" className="text-black-50" />
      </button>
      <div className="request-alert__grid">
        <div className='profile-wrapper'>
          <img src="/images/Oval.png" alt="i-1"/>
        </div>
        <div>
          <h6 className='text-teal text-center text-md-left mb-2 mb-md-1'>Tutor Request</h6>
          <div className="d-flex align-items-center">
            <p className="txt-14--light mr-3">Client: Sheila Pratt</p>
            <p className="txt-14--light mr-3">Date: 16 August, 2020</p>
            <p className="txt-14--light">Time: 8:00AM - 10:00AM</p>
          </div>
        </div>
        <div className='d-flex justify-content-around align-items-center'>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <MdDone fontSize="24px" className='text-teal' />
            <p className='txt-14 text-teal'>Accept</p>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <MdClose fontSize="24px" className='text-danger' />
            <p className='txt-14--light text-danger'>Decline</p>
          </div>
        </div>
        <div className='d-flex align-items-center justify-content-around'>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.5 0C5.14872 0 0 5.14872 0 11.5C0 17.8512 5.14872 23 11.5 23C17.8512 23 23 17.8512 23 11.5C22.9932 5.15156 17.8484 0.00678641 11.5 0ZM11.5 21.3571C6.05603 21.3571 1.64284 16.9439 1.64284 11.5C1.64284 6.05603 6.05603 1.64284 11.5 1.64284C16.9439 1.64284 21.3571 6.05603 21.3571 11.5C21.3512 16.9415 16.9415 21.3512 11.5 21.3571Z" fill="#3D5154"/>
              <path d="M11.5 4.10742C11.0464 4.10742 10.6786 4.47519 10.6786 4.92887V10.6789H4.92862C4.47494 10.6789 4.10718 11.0466 4.10718 11.5003C4.10718 11.954 4.47494 12.3217 4.92862 12.3217H11.5C11.9537 12.3217 12.3215 11.954 12.3215 11.5003V4.92887C12.3215 4.47519 11.9537 4.10742 11.5 4.10742Z" fill="#3D5154"/>
            </svg>
            <p className='txt-14' style={{color: '#3D5154'}}>Propose new time</p>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <svg width="24" height="24" viewBox="0 0 24 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip12)">
              <path d="M24.1951 21.4565L24.7155 19.9323C24.7437 19.8499 24.7435 19.7604 24.7149 19.6781C24.6864 19.5958 24.6312 19.5254 24.558 19.4781L22.2451 17.9824L25.5574 12.2163L25.8678 12.417C25.9367 12.4615 26.0178 12.4833 26.0997 12.4791C26.1815 12.475 26.26 12.4452 26.3241 12.394L28.2528 10.8501C28.3011 10.8114 28.3395 10.7618 28.3649 10.7053C28.3903 10.6489 28.4019 10.5872 28.3988 10.5254C28.3957 10.4636 28.3779 10.4034 28.3469 10.3498C28.316 10.2962 28.2727 10.2507 28.2208 10.2171L20.3483 5.12636C20.2963 5.09275 20.2371 5.07196 20.1755 5.06573C20.114 5.05949 20.0518 5.06798 19.9941 5.09049C19.9365 5.113 19.885 5.1489 19.8439 5.19521C19.8028 5.24152 19.7734 5.29693 19.7579 5.35687L19.1413 7.74921C19.1208 7.8286 19.1259 7.91242 19.1557 7.98879C19.1855 8.06517 19.2386 8.13022 19.3075 8.17473L19.6178 8.37545L15.7183 13.7619L13.4054 12.2662C13.3322 12.219 13.2454 12.1975 13.1586 12.2052C13.0719 12.213 12.9902 12.2495 12.9265 12.309L11.7501 13.4089C11.7067 13.4495 11.6732 13.4994 11.652 13.5548C11.6308 13.6102 11.6225 13.6698 11.6278 13.7289C11.633 13.788 11.6517 13.8452 11.6824 13.896C11.713 13.9468 11.7549 13.99 11.8047 14.0222L16.725 17.2039L10.7858 26.3884C10.7295 26.4754 10.71 26.5812 10.7317 26.6826L10.9635 27.7628C10.9854 27.8641 11.0465 27.9526 11.1335 28.0088C11.2205 28.0651 11.3263 28.0846 11.4276 28.063L12.5079 27.8312C12.6092 27.8093 12.6977 27.7482 12.7539 27.6611L18.6931 18.4766L23.6134 21.6583C23.6632 21.6905 23.7197 21.711 23.7787 21.7181C23.8376 21.7252 23.8974 21.7188 23.9534 21.6993C24.0095 21.6799 24.0604 21.6479 24.1023 21.6058C24.1442 21.5637 24.1759 21.5127 24.1951 21.4565ZM19.9683 7.67168L20.3792 6.07671L27.3414 10.5788L26.0555 11.608L19.9683 7.67168ZM21.5882 17.5577L16.3752 14.1867L20.2747 8.8002L24.9006 11.7915L21.5882 17.5577ZM12.1856 27.1012L11.6455 27.2171L11.5296 26.677L17.381 17.6282L18.037 18.0524L12.1856 27.1012ZM12.6492 13.638L13.2374 13.088L23.8775 19.9684L23.6173 20.7305L12.6492 13.638Z" fill="#009BA7"/>
              </g>
              <defs>
              <clipPath id="clip12">
              <rect width="25" height="25" fill="white" transform="translate(14 0.555664) rotate(32.8888)"/>
              </clipPath>
              </defs>
              </svg>
              <p className='txt-14 text-teal' >Mark as tentative</p>
          </div>
        </div>
      </div>
    </div>
  )
}

RequestAlert.propTypes = {
  show: PropTypes.bool,
  closeFunc: PropTypes.func
}

export default RequestAlert

