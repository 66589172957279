import auth from './auth';

const ROLES = {"2": "tutor", "3": "parent", "4": "student"};

const guard = {

    // guard validation for all authentic pages
    // @ author: Arijit Deb Roy
    // @ params: cb: <func>

    check(cb) {
        if(auth.hasCookie()) {
            typeof cb !== 'undefined' && typeof cb === 'function' && cb();
        }else {
            window.location.href = "/";
        }
    },

    role() {
        return ROLES[auth.check().role_id];
    }

}

export default guard;
