import React from 'react'
import PropTypes from 'prop-types'
import Navbar from './Header/Navbar';
import Footer from './Footer/Footer.component';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from 'react-router-dom';

function Layout(props) {
  return (
    <main className="position-relative" style={{background: '#FFFFFF'}}>
      <Navbar path={props.location.pathname} />
      {props.children}
      <Footer />
        <ToastContainer
            position="bottom-center"
            autoClose={3000}
            hideProgressBar
            transition={Zoom}
            toastClassName="txt-16--body text-white px-3"
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover={false}
        />
    </main>
  )
}

Layout.propTypes = {
  children: PropTypes.node
}

export default withRouter(Layout);

