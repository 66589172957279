import React from 'react';
import { FiCheck } from "react-icons/fi";

const StepperNavigation = ({step, numberOfSteps=3}) => {
    return (
        <div className='signup-step-nav'>
            <ul>
                {
                    [...Array(numberOfSteps)].map((el, index) => (
                        <li className={`${step >= index + 1 ? "active" : ""}`} key={index}>
                            {step > index + 1 ? (
                                <span>
                                <FiCheck />
                                </span>
                            ) : (
                                <span>{index + 1}</span>
                            )}
                        </li>
                    ))
                }
            </ul>
        </div>
    );
}

export default StepperNavigation;